import React, { useState, useEffect } from "react";
import {
  contractAddress,
  abi,
  Withdrawalcontract,
  Withdrawalabi,
  loadWeb3,
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { API } from "../../Utils/Api";
import axios from "axios";
import { toast } from "react-toastify";

const Withdrawal = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const LoginAddress = sessionStorage.getItem("UserAddress").toString(); //myState.address
  const LoginId = sessionStorage.getItem("UserId").toString() != null ? parseInt(sessionStorage.getItem("UserId").toString()) : 0;
  const [userid, setuserid] = useState(LoginId);



  useEffect(() => {
    getWithdrawalBalance();

  }, []);

  const [Wtype, setWtype] = useState("");
  const [NetBalance, setNetBalance] = useState("");
  const [DirectTeamData, setDirectTeamData] = useState([]);
  const [btnDisable, setbtnDisable] = useState(false);

  
  const Witdrawal_Api = async () => {
    try {
      setbtnDisable(true);
      if (LoginAddress) {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(
          Withdrawalabi,
          Withdrawalcontract
        );

        let address = await loadWeb3();
        if (address.toUpperCase() != LoginAddress.toUpperCase()) {
          toast.error(`Address doesnot Macthed !`);
          setbtnDisable(false);
          return;
        }

        // if (Wtype ==="") {
        //   toast.error(`Select Withdrawal Type !`);
        //   setbtnDisable(false);
        //   return;
        // }

        // if (parseFloat(NetBalance) === 0) {
        //   toast.error(`Insufficient Withdrawal Balance!`);
        //   setbtnDisable(false);
        //   return;
        // }

        // Check if the user is not registered
        // if (balanceOf >= NetBalance) {
        // toast.success(Wtype);
        let txnDataSlot = "";

        // if (Wtype === "dividend") {
        //   txnDataSlot = await contract.methods.dividendWithdraw().send({ from: address });
        // }
        // else if (Wtype === "infinitypoolincome") {
        //   txnDataSlot = await contract.methods.distributeInfinityPoolRewards().send({ from: address });
        // }
        // else if (Wtype === "withdraw") {
        //   txnDataSlot = await contract.methods.distributePoolRewards().send({ from: address });
        // }
        txnDataSlot = await contract.methods.withdrawRewards().send({ from: address });

        console.log("resData", txnDataSlot);

        console.log("resData transactionHash", txnDataSlot.transactionHash);
        txnDataSlot = txnDataSlot.transactionHash;

        let responceRight = await axios.post(
          "https://phoenixmatrix-api.nakshtech.info/UserWithdrawl",
          {
            uid: userid,
            address: LoginAddress,
            amount: NetBalance,
            txn: txnDataSlot,
            type: Wtype,
          }
        );

        toast.success("Withdrawal Succesfull");
        console.log("responceRight--->", responceRight);
        setbtnDisable(false);
        // } else {
        //   toast.error("Insufficiant Balance!!");
        //   setbtnDisable(false);
        // }
      } else {
        toast.error("User does not eligible for Buy Level");
        setbtnDisable(false);
      }
    } catch (error) {}
  };

  const getWithdrawalBalance = async () => {
    // setWtype(WithdrawalType);
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Withdrawalabi, Withdrawalcontract);
    const userAddress = LoginAddress;

    // const resultBalance = await contract.methods.userspool(userAddress).call();
    const resultBalance = await contract.methods.checkRewards(userAddress).call();
    console.log(
      "resultBalance-->P5",
      parseFloat(resultBalance.claimableP5) / 10 ** 18
    );
    console.log("resultBalance-->", resultBalance);
    console.log("resultBalance-->P15", parseFloat(resultBalance.claimableP15)/ (10**18));
    let balp5 = parseFloat(resultBalance.claimableP5)/ (10**18);
    let balp15 = parseFloat(resultBalance.claimableP15)/ (10**18);
    setNetBalance((parseFloat(balp5) + parseFloat(balp15)).toFixed(4));
    

    // if (WithdrawalType === "dividend") {
    //   setNetBalance(parseFloat(resultBalance[0]))
    // }
    // else if (WithdrawalType === "infinitypoolincome") {
    //   setNetBalance(parseFloat(resultBalance[1]))
    // }
    // else if (WithdrawalType === "withdraw") {
    //   setNetBalance(parseFloat(resultBalance[2]))
    // }
  };

  return (
    <div className="page-content">
      <div className="">
        <div className="container-fluid">
          {/* <div className="row">

            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-muted text-black">Withdrawal Type</h5>
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <select
                      type="text"
                      className="form-control mt-2"
                      id="exampleInputEmail1"
                      onChange={(e) => getWithdrawalBalance(e.target.value)}>
                      <option>Select Withdrawl Type</option>
                      <option value="dividend">dividend</option>
                      <option value="infinitypoolincome">infinitypoolincome</option>
                      <option value="withdraw">withdraw</option>
                    </select>
                  </span>

                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
          <h2 className="text-muted text-black">P5 * P15 Claim </h2>
          <br/>
          <br></br>
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-muted text-black">Available Balance</h5>
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <input
                      type="text"
                      id="NetBalance"
                      className="inputes"
                      defaultValue={NetBalance}
                      name="NetBalance"
                      readOnly={true}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <button
                      className="btn coped btn-primary"
                      onClick={(e) => {
                        Witdrawal_Api();
                      }}
                      disabled={btnDisable}
                    >
                      Claim
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© Phoenix.live.</div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Withdrawal;
